<template>
  <div class="answer-placeholder">
    <span>
      {{ $attrs.data.placeholder }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'number',
  };
</script>

<style scoped lang="scss">
  @import '../../../styles';
</style>
